const pageHeight = $(document).height();
const viewPort = $(window).height();
let iconsCount;
if($('body').hasClass('home')) {
    iconsCount = 0;
}
else if(pageHeight < 1500) {
    iconsCount = Math.floor(Math.random()) + 2;
}
else if(pageHeight < 2500) {
    iconsCount = Math.floor(Math.random()) + 3;
}
else if(pageHeight < 3500) {
    iconsCount = Math.floor(Math.random() * (5 - 3)) + 3;
}
else if(pageHeight >= 3500) {
    iconsCount = Math.floor(Math.random() * (6 - 4)) + 4;
}

const greyCubes = ["cube-grey-1.png", "cube-grey-2.png", "cube-grey-3.png", "cube-grey-4.png", "cube-grey-5.png", "cube-grey-6.png", "cube-grey-7.png", "cube-grey-8.png"];
const blueCubes = ["cube-blue-1.png", "cube-blue-2.png", "cube-blue-3.png", "cube-blue-4.png", "cube-blue-5.png", "cube-blue-6.png", "cube-blue-7.png", "cube-blue-8.png"];
const greyPyramids = ["pyramid-grey-1.png", "pyramid-grey-2.png", "pyramid-grey-3.png", "pyramid-grey-4.png", "pyramid-grey-5.png", "pyramid-grey-6.png", "pyramid-grey-7.png", "pyramid-grey-8.png"];
const bluePyramids = ["pyramid-blue-1.png", "pyramid-blue-2.png", "pyramid-blue-3.png", "pyramid-blue-4.png", "pyramid-blue-5.png", "pyramid-blue-6.png", "pyramid-blue-7.png", "pyramid-blue-8.png"];
const greyCircles = ["circle-grey.png"];
const blueCircles = ["circle-blue.png"];

const $hero = $('.hero');
let heroHeight;
let pageShape;
if($hero.length > 0 || $('body.single-engagement').length > 0 || $('.engagements').length > 0 || $('.stories').length > 0 || $('#content-section').find('section').first().hasClass('contact-offices') || $('body.single-investment').length > 0) {
    heroHeight = (viewPort/pageHeight)*100;
    if($hero.find('.glide__slide').length > 0) {
        pageShape = $hero.find('.glide__slide').first().data('shape');
    }
    else if($hero.find('.image-wrapper').length > 0) {
        pageShape = $hero.find('.image-wrapper').data('shape');
    }
    else if($('.stories').length > 0) {
        pageShape = $('.stories').find('.image').first().data('shape');
    }
    else if($('.engagements').length > 0) {
        pageShape = 'circle';
    }
    else if($('body.single-engagement').find('.article-hero').length > 0) {
        pageShape = 'circle';
    }
    else if($('body.single-investment').length > 0) {
        pageShape = 'pyramid';
    }
    else if($('#content-section').find('section').first().hasClass('contact-offices')) {
        pageShape = 'circle';
    }
}
else if ($('body').hasClass('privacy-policy')) {
    heroHeight = 6;
}
else {
    heroHeight = 10;
}

let greyShapes = [];
let blueShapes = [];
let topPos;
let leftPos;
if($(window).width() < 768) {
    topPos = [30, 40, 50, 60, 70, 80, 90];
    leftPos = [0, 5, 10, 20, 30, 40, 50, 60, 70, 80, 85, 90, 100];
}
else {
    if(pageHeight < 2500) {
        topPos = [20, 60, 70, 80, 100];
        leftPos = [0, 5, 10, 15, 20, 80, 82, 85, 87, 90];
    }
    else {
        topPos = [40, 60, 70, 80, 100];
        leftPos = [0, 5, 10, 15, 20, 80, 82, 85, 87, 90];
    }
    if($('body').hasClass('page-title-contact')) {
        topPos = [70, 80, 100];
        leftPos = [80, 82, 85, 87, 90];
    }
}

let $size;
let $blueSize;
let randomTopPos;
let randomLeftPos;

for (var i=0; i<iconsCount; i++) {
    $('body #content-section').append('<div class="icon-wrapper"><div class="bg-icon"></div></div>');
    if($(window).width() < 768) {
        $size = Math.floor(Math.random() * (30 - 10)) + 10;
        $blueSize = Math.floor(Math.random() * (20 - 10)) + 10;
    }
    else {
        if(pageHeight < 2500) {
            $size = Math.floor(Math.random() * (15 - 5)) + 5;
        }
        else {
            $size = Math.floor(Math.random() * (15 - 10)) + 10;
        }
        $blueSize = Math.floor(Math.random() * (15 - 8)) + 8;
    }

    if(i == 0) {
        if(pageHeight < 2000) {
            randomTopPos = 100;
        }
        else {
            randomTopPos = heroHeight;
        }
        if($(window).width() >= 768) {
            $size = 15;
        }
        if($('body').hasClass('privacy-policy')) {
            randomLeftPos = 70;
        }
    }
    else if(i == 1) {
        if(pageHeight < 2000) {
            randomTopPos = 20;
            if($(window).width() >= 768) {
                $size = 8;
            }
            else {
                if($hero.length > 0) {
                    randomTopPos = heroHeight;
                    randomLeftPos = 80;
                }
            }
        }
        else {
            randomTopPos = 100;
            $size = 15;
        }
    }
    else {
        randomTopPos = topPos[Math.floor(Math.random() * topPos.length)];
        randomLeftPos = leftPos[Math.floor(Math.random() * leftPos.length)];
    }
    topPos.splice(topPos.indexOf(randomTopPos), 1);
    leftPos.splice(leftPos.indexOf(randomLeftPos), 1);

    if(pageShape == 'cube' || pageShape == 'people' || pageShape == 'team' || pageShape == 'career') {
        $('body').addClass('custom-cursor-cube');
        if(i == 2) {
            $('.bg-icon').eq(i).css({
                'background-image': 'url(/wp-content/themes/grensesnitt/static/shapes/' + blueCubes[~~(Math.random() * blueCubes.length)] +')'
            });
        }
        else {
            $('.bg-icon').eq(i).css({
                'background-image': 'url(/wp-content/themes/grensesnitt/static/shapes/' + greyCubes[~~(Math.random() * greyCubes.length)] +')'
            });
        }
    }
    else if(pageShape == 'circle' || pageShape == 'sustainability' || $('body').hasClass('privacy-policy')) {
        $('body').addClass('custom-cursor-circle');
        if(i == 2) {
            $('.bg-icon').eq(i).css({
                'background-image': 'url(/wp-content/themes/grensesnitt/static/shapes/' + blueCircles[~~(Math.random() * blueCircles.length)] +')'
            });
        }
        else {
            $('.bg-icon').eq(i).css({
                'background-image': 'url(/wp-content/themes/grensesnitt/static/shapes/' + greyCircles[~~(Math.random() * greyCircles.length)] +')'
            });
        }
    }
    else if(pageShape == 'pyramid' || pageShape == 'investment') {
        $('body').addClass('custom-cursor-pyramid');
        if(i == 0) {
            $('.bg-icon').eq(i).css({
                'background-image': 'url(/wp-content/themes/grensesnitt/static/shapes/' + bluePyramids[~~(Math.random() * bluePyramids.length)] +')'
            });
        }
        else if (i == 1) {
            $('.bg-icon').eq(i).css({
                'background-image': 'url(/wp-content/themes/grensesnitt/static/shapes/pyramid-grey-7.png)'
            });
        }
        else {
            $('.bg-icon').eq(i).css({
                'background-image': 'url(/wp-content/themes/grensesnitt/static/shapes/' + greyPyramids[~~(Math.random() * greyPyramids.length)] +')'
            });
        }
    }
    else {
        greyShapes = greyShapes.concat(greyCubes, greyPyramids, greyCircles);
        blueShapes = blueShapes.concat(blueCubes, bluePyramids, blueCircles);
        if(i == 2) {
            $('.bg-icon').eq(i).css({
                'background-image': 'url(/wp-content/themes/grensesnitt/static/shapes/' + blueShapes[~~(Math.random() * blueShapes.length)] +')'
            });
        }
        else {
            $('.bg-icon').eq(i).css({
                'background-image': 'url(/wp-content/themes/grensesnitt/static/shapes/' + greyShapes[~~(Math.random() * greyShapes.length)] +')'
            });
        }
    }
    if(i == 2) {
        $('.icon-wrapper').eq(i).css({
            'width': $blueSize + 'vw',
            'height': $blueSize + 'vw',
        });
    }
    else {
        $('.icon-wrapper').eq(i).css({
            'width': $size + 'vw',
            'height': $size + 'vw',
        });
        if(i == 1) {
            if(pageShape == 'pyramid' || pageShape == 'investment') {
                $('.icon-wrapper').eq(i).css({
                    'width': '20vw',
                    'height': '20vw',
                });
            }
        }
    }
     $('.icon-wrapper').eq(i).css({
        'top': randomTopPos + '%',
        'left': randomLeftPos + '%'
    });
    if(i == 1) {
        if(pageShape == 'pyramid' || pageShape == 'investment') {
            $('.icon-wrapper').eq(i).css({
                'top': '92%',
                'left': '90%'
            });
        }
    }
}

const signs = ['-', '+'];
let rotateSign = [];
let parallaxSign = [];
let speedIcon;
$('.bg-icon').each(function(){
    rotateSign.push(signs[~~(Math.random() * signs.length)]);
    parallaxSign.push(signs[~~(Math.random() * signs.length)]);
});

$(window).scroll( function() {
	var scroll = $(window).scrollTop();
    if($(window).width() < 768) {
        speedIcon = 0.2;
    }
    else {
        speedIcon = 0.15;
    }
    var speedRotate = 0.05;
    
	$('.bg-icon').each(function(index){
        var $this = $(this);
        var $parent = $this.closest('.icon-wrapper');
        var topOffset = $parent.offset().top - viewPort;
        var height = $parent.outerHeight();
        var parallaxSize = (scroll - (topOffset < 0 ? 0 : topOffset)) * speedIcon;
        var rotateSize = (scroll - (topOffset < 0 ? 0 : topOffset)) * speedRotate;
        
        // Prevent parallax when scrolled past elememt
        if(scroll > topOffset + viewPort + height) {
            return;
        }
		
        $this.css({ 
            'transform': scroll >= topOffset ? ('translateY(' + parallaxSign[index] + parallaxSize + 'px) rotate(' + rotateSign[index] + rotateSize + 'deg)' ) : ''
        });
    }); 
}); 
